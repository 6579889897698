import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import '@vant/touch-emulator'
import 'vant/lib/index.css';

const app = createApp(App)
app.use(router).mount('#app')
app.directive('focus', {
  // 当被绑定的元素挂载到 DOM 中时……
  mounted(el) {
    // 聚焦元素
    el.focus()
  }
})