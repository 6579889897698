// 检查设备型号
export const checkPC = () => {
    // 获取设备信息
    var ua = navigator.userAgent.toLowerCase();
    // console.log(ua)
    // 判断是否是微信环境
    // var isWeixin = ua.indexOf('micromessenger') != -1;
    var isAndroid = ua.indexOf('android') != -1;
    var isiPhone = ua.indexOf('iphone') != -1;
    var isMobile = isAndroid || isiPhone
    // var isPC = win || mac
    if (isMobile) {
        return false
    }
    else {
        return true
    }
}