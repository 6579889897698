import { checkPC } from '@/utils/utils'
import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'pc',
    component: () => import(/* webpackChunkName: "about" */ '../views/PagePc.vue')
  },
  {
    path: '/mobile',
    name: 'mobile',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/PageMoblie.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to,from,next)=>{
  // if (!util.checkDevice()){
  //     if ( to.name == 'device-error') {
  //         next()
  //     }
  //     next('/device-error')
  // }
  // else 
  var device = checkPC()
  if (!device){
      if ( to.name == 'mobile') {
          next()
      }
      next('/mobile')
  }
  else {
    next()
  }
  
})


export default router
